@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://unpkg.com/boxicons@2.0.9/css/boxicons.min.css");

*::-webkit-scrollbar {
  width: 7px;
  padding: 4px;
}

*::-webkit-scrollbar-track {
}

::selection {
  @apply bg-Orange text-white;
}

*::-webkit-scrollbar-thumb {
  margin: 4px;
  background-color: rgb(211, 211, 211);
  border: 4px solid transparent;
  border-radius: 100px;
  opacity: 0;
  transition: 0.4s;
}

.btn-CSIBlue {
  @apply bg-CSIBlue hover:bg-CSIBlue hover:bg-opacity-80 text-white;
}

.btn-Gray {
  @apply bg-gray-200 hover:bg-gray-300 text-gray-500;
}

.btn-Red {
  @apply bg-red-500 hover:bg-red-600 text-white;
}

.btn-Green {
  @apply bg-green-500 hover:bg-green-600 text-white;
}

.skeleton {
  @apply bg-gray-300 rounded-md;
}

.input {
  @apply focus:ring-CSIBlue focus:border-CSIBlue sm:text-sm;
}

.imgStyle {
  @apply h-full w-full object-cover rounded-md border border-gray-300 shadow-sm;
}

.btn-Orange-secondary {
  @apply bg-LightOrange bg-opacity-20 text-Orange hover:bg-opacity-30 hover:bg-LightOrange;
  @apply focus:ring-LightOrange focus:ring-2 focus:ring-offset-2 focus:ring-opacity-50;
}

.btn-Orange-primary {
  @apply hover:bg-opacity-80 hover:bg-orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-orange text-sm font-medium text-white bg-orange;
}

#pdf-pagination-next,
#pdf-pagination-prev,
#pdf-zoom-reset {
  @apply bg-white px-3 py-2 text-sm font-semibold rounded-md flex items-center justify-center gap-x-2 w-auto mx-0 text-black;
}

#pdf-download {
  @apply hidden;
}

#pdf-pagination-next,
#pdf-pagination-prev {
  @apply bg-white px-3 py-2 text-sm font-semibold rounded-md flex items-center justify-center gap-x-2 w-auto mx-0;
}

#pdf-controls {
  @apply 2xl:flex-row flex-col items-end gap-y-1 2xl:w-auto gap-x-2 justify-end;
}

#pdf-pagination {
  @apply gap-x-2;
}

#pdf-zoom-reset svg,
#pdf-pagination-prev svg,
#pdf-pagination-next svg {
  @apply hidden;
}

#pdf-pagination-prev::after {
  content: "Prev";
  @apply text-black;
}

#pdf-pagination-next::after {
  content: "Next";
  @apply text-black;
}

#pdf-download::after {
  content: "Download";
  @apply text-black;
}

#pdf-download svg,
#pdf-zoom-out svg,
#pdf-zoom-in svg {
  @apply h-4 w-4;
}

#pdf-zoom-reset::after {
  content: "Reset Zoom";
}

.epub-container {
  height: 100% !important;
}

.react-tel-input .flag-dropdown {
  border: 0px solid #cacaca !important;
}

.react-tel-input .form-control {
  border: 0px solid #cacaca !important;
  background-color: rgba(249, 250, 251) !important;
}
